.stats {
  margin-bottom: 1rem;
  border-right: 2px solid #fff0;
}

.addUser {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.addUser .inputWrapper {
  flex-grow: 1;
  margin-bottom: 0;
}

.greenColor {
  color: #42c900;
}
.redColor {
  color: #ff0000;
}
.thumbnail {
  width: 40px;
  aspect-ratio: 1/1;
  object-fit: contain;
  object-position: center;
  border-radius: 99px;
  margin-right: 0.5rem;
}
.brand_text_icon {
  display: flex;
  align-items: center;
}
.icon_bg {
  background: linear-gradient(to right, #da8cff, #9a55ff) !important;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.mainTitle {
  font-size: 1.3rem;
  margin-left: 10px;
  margin-top: 10px;
  color: #343a40;
  font-family: "ubuntu-medium", sans-serif;
}
.mainTitleAdd {
  font-size: 1.3rem;
  margin-top: 10px;
  color: #343a40;
  font-family: "ubuntu-medium", sans-serif;
}
.dashCard {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
}
.mainInputAdd {
  background: #fff;
  font-weight: 400;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 20%) !important;
  padding: 12px 15px;
  font-size: 15px;
  color: #141414;
  width: 100%;
  resize: none;
  border-radius: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
}
.data-table {
  /* padding-right: 20px; */
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .stats,
  .stats:not(:last-child) {
    border-color: #fff0;
  }

  .stats:nth-child(odd) {
    border-color: #000;
  }
}

@media screen and (max-width: 767px) {
  .stats,
  .stats:not(:last-child),
  .stats:nth-child(odd) {
    border-color: #fff0;
  }
}
