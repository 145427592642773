.stats {
  margin-bottom: 1rem;
  border-right: 2px solid #fff0;
}

.addUser {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}
.searchBar {
  display: flex;
  align-items: center;
  width: 100%;
}
.status-button {
  padding: 10px 0px;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;
  display: block;
  font-size: 0.75rem;
  width: 100%;
  background-color: grey;
}

.published {
  /* background: linear-gradient(to right, #84d9d2, #07cdae) !important; */
}

.unpublished {
  /* background: linear-gradient(to right, #da8cff, #9a55ff) !important; */
}

.under-check {
  /* background: linear-gradient(to right, #90caf9, #047edf 99%) !important; */
}

.refused {
  /* background: linear-gradient(to right, #f48fb1, #f06292) !important; */
}

.active-filter {
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(
    45deg,
    #4caf50,
    #81c784
  ); /* Gradient for a fresh look */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
  transition: all 0.3s ease-in-out; /* Smooth animation for hover/active effect */
  font-weight: 500;
}

.active-filter:hover {
  background: linear-gradient(
    45deg,
    #388e3c,
    #66bb6a
  ); /* Slightly darker hover effect */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3); /* Enhance shadow on hover */
}

.seacrfield {
  width: 100%;
}
.addUser .inputWrapper {
  flex-grow: 1;
  margin-bottom: 0;
}
.checkBox-wrap {
  display: flex;
  align-items: center;
}
.check-box-custom {
  transform: scale(1.5); /* Increases the size of the checkbox */
  margin-left: 20px;
}
.detail_box {
  box-shadow: 1px 1px 10px 0px rgba(115, 103, 103, 0.75);
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 20px;
  padding-left: 20px;
}
.secondaryText {
  font-size: 15px;
  /* font-weight: 700; */
  color: #333;
  margin-bottom: 0.2rem;
}
.pagination-controls {
  width: 100%;
  margin-top: 20px;
  display: flex;
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .stats,
  .stats:not(:last-child) {
    border-color: #fff0;
  }

  .stats:nth-child(odd) {
    border-color: #000;
  }
}

@media screen and (max-width: 767px) {
  .stats,
  .stats:not(:last-child),
  .stats:nth-child(odd) {
    border-color: #fff0;
  }
}
