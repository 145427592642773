.backButton {
    font-size: 12px;
    color: #fff;
    /* background-color: linear-gradient(to left, #da8cff, #9a55ff) !important; */
    background-color:  #9a55ff;
    border-radius: 99px;
    border: none;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.2rem;
    transition: 0.3s linear;
}

.backButton:hover {
    /* background: #000; */
}