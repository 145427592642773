.pagination-controls {
  width: 100%;
  margin-top: 20px;
}

button {
  border-radius: 0.375rem; /* Border radius for the buttons */
  font-size: 1.2rem; /* Default font size for icons */
}

button.btn-sm {
  font-size: 1rem; /* Smaller font size for small buttons */
}

.text-center {
  font-size: 1.2rem; /* Optional: for better font size */
}

/* Optional: Ensuring buttons have space between them */
.d-flex.justify-content-end button {
  margin-left: 10px; /* space between buttons */
}

/* Optional: additional responsive adjustments */
@media (max-width: 767px) {
  .pagination-controls .d-flex.justify-content-center {
    justify-content: center !important; /* Ensure buttons are centered on small screens */
  }
}
