.closeButton {
  font-size: 16px;
  color: #fff;
  /* background: var(--primaryColor); */
  background-color: #9a55ff;
  width: 30px;
  height: 30px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  margin: 0.5rem;
  margin-left: auto;
}
.modalImage {
  width: 90px;
  margin-bottom: 1rem;
}
.modalContent {
  margin: 1rem 0;
}
.modalHeading {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 1rem;
}
