.sidebar {
  height: 100vh;
  overflow: auto;
  position: fixed;
  padding-bottom: 30px;
}

@media (max-width: 991px) {
  .sidebarl {
    margin-left: 0;
    width: 100%;
    position: relative;
  }
}
