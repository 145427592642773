.image-preview-container {
  display: inline-block;
  margin-right: 10px;
  text-align: center;

  .image-preview {
    width: 120px;
    height: 120px;
    /* object-fit: contain; */
  }

  .delete-button-container {
    margin-top: 10px;
  }

  button {
    display: inline-block;
    text-align: center;
    padding: 5px 10px;
  }
}
.checkbox-left-margin {
  margin-left: 25px;
}
