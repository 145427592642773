.customTable {
  overflow-x: auto;
}

.arrowTables {
  position: absolute;
  left: -7px;
}

.arrowTables button {
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 12px;
}

.empty {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

th {
  position: relative;
}

.customTable table {
  font-size: 14px;
  padding: 0px 0.5rem;
  width: 100%;
}

.customTable table thead tr,
.customTable table tbody tr:nth-child(even) {
  background: #f5f5f5;
}

.customTable table th,
.customTable table td {
  padding: 0.8rem 1rem;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}

.customTable table th {
  text-transform: uppercase;
}

.customTable .thumbnail {
  width: 40px;
  aspect-ratio: 1/1;
  object-fit: contain;
  object-position: center;
  border-radius: 99px;
  margin-right: 0.5rem;
}

.tableDropdownMenu {
  padding: 0;
  border-radius: 0.3rem;
  min-width: auto;
  text-align: left;
  overflow: hidden;
  border: 1px solid #e0e0e0;
}

.tableAction {
  font-size: 15px;
  font-weight: 600;
  display: block;
  text-align: left;
  text-decoration: none;
  padding: 0.5rem 1rem;
  color: #333;
  background: #fff0;
  border: none;
  outline: none;
  width: 100%;
  transition: 0.3s linear;
}

.tableAction:hover {
  /* background: var(--primaryColor); */
  background-color: red;
  color: #fff;
}

.tableActionIcon {
  font-size: 15px;
  margin-right: 0.5rem;
  width: 1rem;
}
