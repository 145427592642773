.stats {
  margin-bottom: 1rem;
  border-right: 2px solid #fff0;
}

.addUser {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.addUser .inputWrapper {
  flex-grow: 1;
  margin-bottom: 0;
}
.checkBox-wrap {
  display: flex;
  align-items: center;
}
.check-box-custom {
  transform: scale(1.5); /* Increases the size of the checkbox */
  margin-left: 20px;
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .stats,
  .stats:not(:last-child) {
    border-color: #fff0;
  }

  .stats:nth-child(odd) {
    border-color: #000;
  }
}

@media screen and (max-width: 767px) {
  .stats,
  .stats:not(:last-child),
  .stats:nth-child(odd) {
    border-color: #fff0;
  }
}
