.customButton {
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  border: 1px solid #9a55ff;
  border-radius: 6px;
  /* padding: 0.7rem 3.2rem; */
  padding: 16px;
  min-width: 120px;
  position: relative;
  color: #fff;
  transition: 0.3s linear;
  cursor: pointer;
  background: linear-gradient(to right, #da8cff, #9a55ff) !important;
}

.customButton2 {
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  border: 1px solid;
  border-radius: 6px;
  padding: 0.7rem 3.2rem;
  min-width: 120px;
  position: relative;
  transition: 0.3s linear;
  cursor: pointer;
}

.primaryButton {
  /* background: var(--primaryColor); */
  /* background-color: purple; */
  background: linear-gradient(to right, #da8cff, #9a55ff) !important;

  color: #fff;
  /* border-color: var(--primaryColor); */
  border: purple;
}

.hoveredButton:hover {
  /* color: var(--primaryColor); */
}

.rounded-50 {
  width: 50px !important;
  height: 50px !important;
  min-width: 50px;
  padding: 0;
  border-radius: 50px;
}

.secondaryButton {
  background: #fff0;
  color: #000;
  /* border-color: var(--primaryColor); */
}
