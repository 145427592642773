/* Modal Header */
.status-modal-header {
  border-bottom: none;
  text-align: center;
}

.status-modal-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin: auto;
  text-align: center;
}

/* Modal Body */
.status-modal-body {
  padding: 20px;
}

.status-modal-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Status Option Styles */
.status-option {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.status-option input {
  margin-right: 10px;
}

.status-option:hover {
  color: #007bff;
  border: 1px solid #007bff;
  background-color: rgba(0, 123, 255, 0.1); /* Light blue background */
}

.active-option {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}

.status-label {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

/* Modal Footer */
.status-modal-footer {
  border-top: none;
  justify-content: center;
}

.status-close-btn {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #6c757d;
  border: none;
}
