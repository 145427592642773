.summary-card {
    text-align: center;
    padding: 20px;
  }
  
  .table-header {
    background-color: #f5f5f5;
  }
  
  .action-icon {
    margin: 0 5px;
    cursor: pointer;
  }
  
  .green-color {
    color: green;
  }
  
  .red-color {
    color: red;
  }
  
  .add-button {
    margin-bottom: 20px;
  }
  .custom-label {
    font-size: 14px; /* Adjust size as needed */
    font-weight: 600;
  }
  