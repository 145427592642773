.user-dropdown:hover {
  background-color: transparent;
  color: rgb(0, 30, 255);
  text-decoration: none;
}

.notification-container {
  position: relative;
  display: flex;
  align-items: center;
}

.notification-icon {
  cursor: pointer;
}

.notification-count {
  position: relative;
  top: -15px;
  right: 5px;
  background-color: rgb(0, 30, 255);
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
}
.dropdown-item-nav {
  display: block;
  width: 100%;
  padding: 0.875rem;
  font-weight: 400;
  color: #343a40;
  font-size: 1rem;
  text-decoration: none;
}

.notification-dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  min-width: 350px;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 250px;
  max-width: 350px;
  overflow: auto;
}

.notification-list li {
  margin-bottom: 8px;
}

.mark-read-button {
  display: block;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 12px;
}

.close-button {
  float: right;
  background-color: transparent;
  color: #777;
  border: none;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
}

.mark-read-button,
.close-button {
  margin-top: 10px;
  margin-right: 10px;
  padding: 6px 12px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.mark-read-button:hover,
.close-button:hover {
  background-color: #007bff;
  color: white;
}

.list-item {
  border-bottom: 0.5px solid #ddd;
  padding-bottom: 10px;
}
